import {
  Container,
  TextField,
  Typography,
  Box,
  FormControl,
  FormLabel,
  Button,
  CircularProgress,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { showErrorMsg, showSuccessMsg } from "../services/event-bus.service";
import { searchService } from "../services/search.service";
import { Form, Link, useNavigate } from "react-router-dom";
import PatentValidator from "../services/validation.service";
import { userService } from "../services/user.service";
import ConfirmCreditForSearch from "../components/pre-search/confirmCreditForSearch";

import { StatusUpdatesContext } from "../services/search.status.updates.context";

const PreClaimSearch = () => {
  const [searchTermQuery, setSearchTermQuery] = useState("");
  const [searchTermQueryValidationError, setsearchTermQueryValidationError] =
    useState("");
  const [searchId, setSearchId] = useState("");
  const [preSeachTerm, setPreSearchTerm] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [sumConfirmPatents, setSumConfirmPatents] = useState(0);
  const [validPatents, setValidPatents] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false); // Controls loading spinner
  const [searchAvailable, setSearchAvailable] = useState(false);
  const { initiateStatusUpdates } = useContext(StatusUpdatesContext);
  const [claimText, setClaimText] = useState("");
  const storageToken = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (!storageToken) {
      navigate("/");
    }
  }, [storageToken]);
  useEffect(() => {
    const userName = localStorage.getItem("userName");
    if (userName) {
      fetchLoggedInUser();
    } else {
      setLoggedInUser({});
    }
  }, []);

  const fetchLoggedInUser = async () => {
    try {
      const loggedIn = await userService.getLoggedInUser();
      setLoggedInUser(loggedIn);
    } catch (error) {
      console.error("Failed to load logged-in user:", error);
    }
  };

  const handleSearchQuery = async () => {
    if (!searchTermQuery.trim()) {
      showErrorMsg("Search term cannot be empty.");
      return;
    }

    setLoading(true);
    const { validPatents: validatedPatents, invalidPatents } =
      PatentValidator.validatePatents(searchTermQuery);

    // Use validatedPatents here instead of validPatents
    if (validatedPatents.length > 1) {
      showErrorMsg("You can only search for one patent at a time.");
      setLoading(false);
      return;
    }

    if (invalidPatents.length > 0) {
      showErrorMsg(
        "Please check the following invalid patents: " +
          invalidPatents.join(", ")
      );
      setLoading(false);
      return;
    }

    if (validatedPatents.length > 0 && invalidPatents.length === 0) {
      setValidPatents(validatedPatents);
      console.log("Valid patents:", validatedPatents);

      try {
        console.log("Validating patents with server...");
        const validData = await searchService.validatePatent(validatedPatents);
        setSearchAvailable(validData?.searches_available);

        const inValidData = validData?.patents
          .filter((patent) => patent.is_valid === false)
          .map((patent) => patent.patent_number);
        console.log("show invalid data*****", inValidData);

        if (inValidData.length > 0) {
          showErrorMsg(
            `Failed to validate patents. These are the invalid patents: ${inValidData.join(
              ", "
            )}`
          );
          setLoading(false);
          return;
        }

        // Sum the claims from validData
        const sumConfirmPatents = validData?.patents.reduce((acc, curr) => {
          console.log("curr.claims", curr.claims);
          return acc + curr.claims;
        }, 0);
        setSumConfirmPatents(sumConfirmPatents);

        setShowConfirm(true);
        setLoading(false);
      } catch (error) {
        console.error("Error during patent validation:", error);
        showErrorMsg("Failed to validate patents.");
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleConfirmSearch = async () => {
    setShowConfirm(false);
    setLoading(true);

    try {
      console.log("User confirmed, starting search...");
      const data = await searchService.startSearch(
        validPatents,
        initiateStatusUpdates,
        1, // in this pre claim search , we only search for the first claim
        claimText
      );
      setPreSearchTerm(validPatents);

      const searchIds = data?.search_ids || [];
      setSearchId(searchIds);
      setSearchTermQuery("");
      setClaimText("");
    } catch (error) {
      console.error("Error during search:", error);
      showErrorMsg("Failed to start search.");
    } finally {
      setLoading(false);
    }
  };
  const handleSearchTermQueryChange = (event) => {
    setSearchId("");
    setSearchTermQuery(event.target.value);
    validateSearchTermFormatInput(event.target.value);
  };

  const validateSearchTermFormatInput = (value) => {
    const trimmedValue = value.trim();
    // Updated regex to include new line character (\n)
    const regex = /^[A-Z0-9,; \n]*$/;
    if (!regex.test(trimmedValue)) {
      setsearchTermQueryValidationError(
        "Only uppercase letters, numbers, comma, semicolon, space, and newline characters are allowed."
      );
    } else {
      setsearchTermQueryValidationError("");
    }
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: { xs: 2, sm: 3 },
      }}
    >
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          width: { xs: "100%", sm: "100%", md: "50%" },
          marginBottom: "10px",
        }}
      >
        <FormLabel
          sx={{
            marginBottom: "8px",
          }}
        >
          Patent Number:
        </FormLabel>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search Patent Number..."
            multiline
            rows={1}
            sx={{
              marginBottom: "5px",
            }}
            error={searchTermQueryValidationError}
            value={searchTermQuery}
            helperText={searchTermQueryValidationError}
            onChange={handleSearchTermQueryChange}
          />
          <Typography
            sx={{
              fontSize: "0.75rem",
              color: "text.secondary",
              textAlign: "left",
              marginBottom: "16px",
            }}
          >
            Enter valid US Patent Number e.g. US12085531B2
          </Typography>
          <FormLabel
            sx={{
              marginBottom: "8px",
            }}
          >
            Claim Text:
          </FormLabel>{" "}
          <TextField
            variant="outlined"
            placeholder="Search Claim Text..."
            multiline
            rows={4}
            sx={{
              marginBottom: "20px",
            }}
            value={claimText}
            onChange={(e) => setClaimText(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{
              height: "56px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "0 16px",
              color: "white",
            }}
            onClick={handleSearchQuery}
            disabled={searchTermQueryValidationError}
          >
            Search
          </Button>
          {preSeachTerm?.map((patent) => (
            <Typography key={patent} sx={{ mt: 2 }}>
              <Link to={`/archive/${patent}`} style={{ color: "#1976d2" }}>
                Click here to see results for {patent}
              </Link>
            </Typography>
          ))}
        </Box>
      </FormControl>

      {loading && <CircularProgress />}
      {showConfirm && (
        <ConfirmCreditForSearch
          credit={1}
          searchAvailable={searchAvailable}
          onConfirm={handleConfirmSearch}
          onClose={handleCloseConfirm}
        />
      )}
    </Container>
  );
};

export default PreClaimSearch;
