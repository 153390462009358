import React from "react";
import { Typography, Box, List, Checkbox, Tooltip } from "@mui/material";

const PreSearchAssigneeList = ({
  items,
  selectedItems,
  onCheckboxChange,
  onSelectAll,
}) => {
  // Fixed select all logic
  const availableItemIds = items.map((item) => item.publication_number);
  const allSelected =
    items.length > 0 &&
    availableItemIds.every((id) => selectedItems.includes(id));

  const partiallySelected =
    selectedItems.length > 0 && selectedItems.length < items.length;

  const checkboxStyle = {
    "&.MuiCheckbox-root": {
      color: "#bdbdbd",
    },
    "&.Mui-checked": {
      color: "#1976d2",
    },
    "&.MuiCheckbox-indeterminate": {
      color: "#1976d2",
    },
    "& .MuiSvgIcon-root": {
      fontSize: 24,
      visibility: "visible",
    },
    padding: "9px",
  };

  return (
    <List sx={{ marginTop: "20px", paddingBottom: "40px" }}>
      {items.length > 0 && (
        <Box
          sx={{
            padding: "10px 0",
            borderBottom: "1px solid #e8e8e8",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title="Select/Deselect All">
            <Checkbox
              checked={allSelected}
              indeterminate={partiallySelected}
              onChange={onSelectAll}
              sx={checkboxStyle}
            />
          </Tooltip>
          <Typography variant="body1" fontWeight="bold">
            {allSelected ? "Deselect All" : "Select All"}{" "}
          </Typography>
        </Box>
      )}
      {items.length > 0 ? (
        items.map((item, index) => (
          <Box
            key={index}
            sx={{
              padding: "10px 0",
              borderBottom: "1px solid #e8e8e8",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="Check the patent you are looking for">
              <Checkbox
                checked={selectedItems.includes(item.publication_number)}
                onChange={() => onCheckboxChange(item.publication_number)}
                sx={checkboxStyle}
              />
            </Tooltip>
            {item.thumbnail && (
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  marginRight: "15px",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={item.thumbnail}
                  alt={item.title}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            )}
            <Box sx={{ flex: 1 }}>
              <Box
                component="a"
                href={`https://patents.google.com/${item.patent_id}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="primary.main"
                >
                  {item.publication_number} - {item.assignee}
                </Typography>
                <Typography variant="body2" color="gray">
                  {item.publication_date}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.title}
              </Typography>
            </Box>
          </Box>
        ))
      ) : (
        <Typography variant="body2" color="textSecondary" textAlign="center">
          No results found.
        </Typography>
      )}
    </List>
  );
};

export default PreSearchAssigneeList;
